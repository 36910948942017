import React, { FC } from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { useStyles, useSettings } from '@wix/tpa-settings/react';
import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';

import { SimpleReward, SimpleRule } from '../../../types/domain';
import { Header } from './header';
import stylesParams, { PageLayout } from '../stylesParams';
import { EmptyState } from './empty-state';
import { TabState } from '../../../types/settings';
import { ErrorState } from './error-state';
import { DetailsCard } from './details-card';
import { classes, style } from './widget.st.css';
import settingsParams from '../settingsParams';
import { DataHook } from '../../../types/data-hook';

export type ControllerProps = {
  hasError: boolean;
  tabState: TabState;
  loyaltyProgram: LoyaltyProgram;
  isLoggedIn: boolean;
  isMyRewardsPageInstalled: boolean;
  navigateToMyRewards: () => void;
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
};

const layoutToStateMap = {
  [PageLayout.Grid]: 'grid',
  [PageLayout.List]: 'list',
  [PageLayout.Side]: 'side',
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  hasError,
  tabState,
  loyaltyProgram,
  isLoggedIn,
  navigateToMyRewards,
  isMyRewardsPageInstalled,
  simpleRules,
  simpleRewards,
}) => {
  const styles = useStyles();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const pageLayout = styles.get(stylesParams.pageLayout) as PageLayout;
  const iconPath = loyaltyProgram?.pointDefinition?.icon?.url;

  if (hasError) {
    return <ErrorState />;
  }

  if (loyaltyProgram.status !== ProgramStatus.ACTIVE) {
    return <EmptyState />;
  }

  return (
    <>
      <div
        className={style(classes.root, {
          pageLayout: isMobile ? 'mobile' : layoutToStateMap[pageLayout],
        })}
        data-hook={DataHook.LoyaltyPageWrapper}
      >
        {styles.get(stylesParams.showHeader) ? (
          <Header
            loyaltyProgram={loyaltyProgram}
            isLoggedIn={tabState ? tabState === TabState.AfterSignUp : isLoggedIn}
            isMyRewardsPageInstalled={isMyRewardsPageInstalled}
            navigateToMyRewards={navigateToMyRewards}
          />
        ) : null}
        {styles.get(stylesParams.showProgramContent) ? (
          <>
            <DetailsCard
              dataHook={DataHook.DetailsCardSignUp}
              step={1}
              iconPath={iconPath ?? ''}
              title={settings.get(settingsParams.signUpTitle)}
              details={[{ description: settings.get(settingsParams.signUpDescription) }]}
            />
            <DetailsCard
              dataHook={DataHook.DetailsCardEarnPoints}
              step={2}
              iconPath={iconPath ?? ''}
              title={settings.get(settingsParams.earnPointsTitle)}
              details={simpleRules}
            />
            <DetailsCard
              dataHook={DataHook.DetailsCardRedeemPoints}
              step={3}
              iconPath={iconPath ?? ''}
              title={settings.get(settingsParams.redeemPointsTitle)}
              details={simpleRewards}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default Widget;
