import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/tpa-settings';

export enum ButtonStyle {
  EMPTY_ROUNDED = 0,
  EMPTY_CORNERED = 1,
  FULL_ROUNDED = 2,
  FULL_CORNERED = 3,
}

export enum Alignment {
  Left = 0,
  Center = 1,
  Right = 2,
}

export enum PageLayout {
  Grid = 0,
  List = 1,
  Side = 2,
}

export enum StepThumbnail {
  Numbers = 0,
  Icon = 1,
}

type IStylesParams = {
  showHeader: StyleParamType.Boolean;
  showCoverImage: StyleParamType.Boolean;
  showProgramName: StyleParamType.Boolean;
  showProgramTitle: StyleParamType.Boolean;
  showProgramContent: StyleParamType.Boolean;
  showStepIndication: StyleParamType.Boolean;

  pageLayout: StyleParamType.Number;

  headerHeight: StyleParamType.Number;
  headerSidePadding: StyleParamType.Number;
  headerAlignment: StyleParamType.Number;
  headerSplit: StyleParamType.Boolean;

  cardsSpacing: StyleParamType.Number;
  cardsPosition: StyleParamType.Number;
  cardsMargin: StyleParamType.Number;
  cardsStepThumbnail: StyleParamType.Number;
  cardsContentAlignment: StyleParamType.Number;
  cardsPadding: StyleParamType.Number;

  headerBackgroundColorNew: StyleParamType.Color;
  headerImageOpacity: StyleParamType.Number;
  headerBorderRadius: StyleParamType.Number;
  headerSplitBackgroundColor: StyleParamType.Color;

  programNameFontNew: StyleParamType.Font;
  programNameColorNew: StyleParamType.Color;
  programTitleFontNew: StyleParamType.Font;
  programTitleColorNew: StyleParamType.Color;

  headerShowShadow: StyleParamType.Boolean;
  headerShadowAngle: StyleParamType.Number;
  headerShadowDistance: StyleParamType.Number;
  headerShadowBlur: StyleParamType.Number;
  headerShadowColor: StyleParamType.Color;

  buttonTextFont: StyleParamType.Font;
  buttonTextColorNew: StyleParamType.Color;
  buttonBackgroundColorNew: StyleParamType.Color;
  buttonBorderColorNew: StyleParamType.Color;
  buttonBorderWidth: StyleParamType.Number;
  buttonBorderRadius: StyleParamType.Number;

  detailsBackgroundColor: StyleParamType.Color;
  detailsBorderWidth: StyleParamType.Number;
  detailsBorderColor: StyleParamType.Color;
  detailsCornerRadius: StyleParamType.Number;

  detailsShowShadow: StyleParamType.Boolean;
  detailsShadowAngle: StyleParamType.Number;
  detailsShadowDistance: StyleParamType.Number;
  detailsShadowBlur: StyleParamType.Number;
  detailsShadowColor: StyleParamType.Color;

  detailsStepIndicationFont: StyleParamType.Font;
  detailsStepIndicationColor: StyleParamType.Color;
  detailsStepFont: StyleParamType.Font;
  detailsStepColor: StyleParamType.Color;
  detailsTitleFont: StyleParamType.Font;
  detailsTitleColor: StyleParamType.Color;
  detailsDescriptionFont: StyleParamType.Font;
  detailsDescriptionColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  showHeader: { type: StyleParamType.Boolean, getDefaultValue: () => true },
  showCoverImage: { type: StyleParamType.Boolean, getDefaultValue: () => true },
  showProgramName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showProgramTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showProgramContent: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showStepIndication: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },

  pageLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => PageLayout.Grid,
  },

  headerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 300,
  },
  headerSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  headerAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const layout = params.getStyleParamValue({ type: StyleParamType.Number, key: 'pageLayout' });
      return layout === PageLayout.Side ? Alignment.Left : Alignment.Center;
    },
  },
  headerSplit: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },

  cardsSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  cardsPosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  cardsMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  cardsStepThumbnail: {
    type: StyleParamType.Number,
    getDefaultValue: () => StepThumbnail.Numbers,
  },
  cardsContentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: (params) => {
      const layout = params.getStyleParamValue({ type: StyleParamType.Number, key: 'pageLayout' });
      return layout === PageLayout.Grid || !layout ? Alignment.Center : Alignment.Left;
    },
  },
  cardsPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },

  headerBackgroundColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: () => ({
      value: '#000000',
      opacity: 1,
      name: null,
    }),
  },
  headerImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  headerBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerSplitBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0),
  },

  programNameFontNew: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 32,
    }),
  },
  programNameColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  programTitleFontNew: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  programTitleColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },

  headerShowShadow: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  headerShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  headerShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.15),
  },

  buttonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  buttonTextColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonBackgroundColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderColorNew: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },

  detailsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  detailsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  detailsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  detailsCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },

  detailsShowShadow: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  detailsShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  detailsShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  detailsShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  detailsShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.15),
  },

  detailsStepIndicationFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  detailsStepIndicationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsStepFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-XL', {
      size: 24,
    }),
  },
  detailsStepColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-XL', {
      size: 16,
    }),
  },
  detailsTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  detailsDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});
