import React from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { IMAGE_ROUTE } from '../../../constants/image-route';
import stylesParams, { Alignment, PageLayout, StepThumbnail } from '../stylesParams';
import { style, classes, vars } from './details-card.st.css';
import { getShadowOffsets } from '../../../utils/get-shadow-angles';

type DetailsItem = {
  title?: string;
  description: string;
};

interface DetailsCardProps {
  step: number;
  title: string;
  details: DetailsItem[];
  iconPath: string;
  dataHook?: string;
}

const alignmentToStateMap = {
  [Alignment.Left]: 'left',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'right',
};

const layoutToStateMap = {
  [PageLayout.Grid]: 'grid',
  [PageLayout.List]: 'list',
  [PageLayout.Side]: 'side',
};

export const DetailsCard: React.FC<DetailsCardProps> = ({ step, title, details, iconPath, dataHook }) => {
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const showStepIndication = styles.get(stylesParams.showStepIndication);
  const shadowOffsets = getShadowOffsets({
    angle: styles.get(stylesParams.detailsShadowAngle),
    distance: styles.get(stylesParams.detailsShadowDistance),
  });
  const showIcon = styles.get(stylesParams.cardsStepThumbnail) === StepThumbnail.Icon;
  let pageLayout = styles.get(stylesParams.pageLayout) as PageLayout;
  const alignment = styles.get(stylesParams.cardsContentAlignment) as Alignment;

  if (isMobile) {
    pageLayout = PageLayout.Grid;
  }

  const renderDetails = () => {
    let layout = layoutToStateMap[pageLayout];

    if (isMobile) {
      layout = layoutToStateMap[PageLayout.Grid];
    }

    return (
      <div className={classes.details}>
        {details.map((detail, index) => {
          return (
            <div className={classes.detail}>
              {detail.title ? <p className={classes.detailTitle}>{detail.title}</p> : null}
              <p className={classes.detailDescription} data-hook={`${dataHook}-description-${index}`}>
                {detail.description}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      data-hook={dataHook}
      className={style(classes.root, {
        alignment: alignmentToStateMap[alignment],
        pageLayout: layoutToStateMap[pageLayout],
        isMobile,
        showShadow: styles.get(stylesParams.detailsShowShadow),
      })}
      style={{ [vars.shadowXOffset]: shadowOffsets.xOffset + 'px', [vars.shadowYOffset]: shadowOffsets.yOffset + 'px' }}
    >
      <div className={classes.header}>
        {showStepIndication ? (
          showIcon ? (
            <img src={IMAGE_ROUTE(iconPath)} />
          ) : (
            <div className={classes.step}>{`0${step}`}</div>
          )
        ) : null}
        <h2 data-hook={`${dataHook}-title`}>{title}</h2>
      </div>
      {renderDetails()}
    </div>
  );
};
